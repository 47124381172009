import mapPagesWordpress from '@/statics/mapPagesWordpress.json'
import mapLinksExternos from '@/statics/mapLinksExternos.json'
import mapLinksInternos from '@/statics/mapLinksInternos.json'

export function getPrefeituraPages(){
  return [
    mapPagesWordpress.prefeito,
    mapPagesWordpress.vicePrefeito,
    mapPagesWordpress.estruturaOrganizacional,
    mapPagesWordpress.exPrefeitos,
    // mapLinksInternos.secretarias
  ]
}

export function getAtosOficiaisPages(){
  return [
    mapLinksInternos.publicacoesOficiais
  ]
}

export function getSecretariasPages(){
  return [
    mapPagesWordpress.gabinetePrefeito,
    mapPagesWordpress.procuradoriaGeral,
    mapPagesWordpress.secretariaSaude,
    mapPagesWordpress.secretariaAdministracao,
    mapPagesWordpress.secretariaAgricultura,
    mapPagesWordpress.secretariaAassistenciaSocial,
    mapPagesWordpress.secretariaEsporte,
    mapPagesWordpress.secretariaFinancas,
    mapPagesWordpress.secretariaAmbiente,
    mapPagesWordpress.secretariaObras,
    mapPagesWordpress.secretariaEducacao,
  ]
}

export function getCidadaoPages(){
  return [
    mapPagesWordpress.portalServicos,
    mapPagesWordpress.acessoInformacao,
    mapPagesWordpress.assessoriaGeral,
    mapLinksExternos.esic,
    mapLinksExternos.ouvidoria,
    mapPagesWordpress.perguntasFrequentes,
    mapLinksInternos.noticias,
    mapPagesWordpress.telefonesUteis,
  ]
}

export function getTurismoPages(){
  return [
    mapPagesWordpress.cultura,
    mapPagesWordpress.comoChegar,
    mapPagesWordpress.ondeComer,
    mapPagesWordpress.comoDeslocar,
    mapPagesWordpress.historia,
    mapPagesWordpress.geografia,
    mapPagesWordpress.economia,
    mapPagesWordpress.simbolos,
    mapPagesWordpress.localidades,
  ]
}

export function getServidorPages(){
  return [
    mapPagesWordpress.servidores,
    mapPagesWordpress.cronogramaSalarios,
    mapLinksExternos.glpiEL,
    mapLinksExternos.webEmail,
    mapLinksExternos.contraCheque,
    mapLinksExternos.contraChequeSaude,
  ]
}

export function getLegislacaoPages(){
  return [
    mapLinksInternos.normativas,
    mapLinksExternos.leiOrganica,
    mapLinksExternos.legislacaoMunicipal,
    mapLinksExternos.legislacaoEstadual,
    mapLinksExternos.legislacaoFederal,
  ]
}

export function getTransparenciaPages(){
  return [
    mapLinksInternos.licitacoes,
    mapLinksInternos.concursos,
    mapLinksExternos.portalTrasnparenciaEL,
    mapLinksInternos.publicacoesOficiais,
    mapPagesWordpress.pesquisaLDOGeral
  ]
}

export default {
  mapPagesWordpress,
  mapLinksExternos,
  mapLinksInternos,
  getPrefeituraPages,
  getAtosOficiaisPages,
  getSecretariasPages,
  getCidadaoPages,
  getTurismoPages,
  getServidorPages,
  getLegislacaoPages,
  getTransparenciaPages,
}
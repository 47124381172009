import { Component, Vue } from 'vue-property-decorator'
import {} from '@/components/base';
import config from '@/config'
  
@Component({
  components: {}
})
export default class Footer extends Vue {
  version = config.VERSION;
}
  
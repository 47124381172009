import { Component, Vue } from "vue-property-decorator";
import links from "@/services/links";
import { Icons } from "@/components/base/index";

@Component({
  components: {
    Icons,
  },
})
export default class SolicitacaoIcons extends Vue {
  linksExternos = links.mapLinksExternos;
  showModal = false;
  inputBuscar = "";

  onClickBuscar() {
    this.$router.push(`/busca?q=${this.inputBuscar}`);
    this.showModal = false;
    this.inputBuscar = "";
  }
}

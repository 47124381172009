import { Component, Vue, Prop } from 'vue-property-decorator'
  
@Component({
  components: {}
})
export default class Icons extends Vue {
  @Prop({default: ''}) library!: string;
  @Prop() name!: string;
  @Prop() size!: string;

  svgLocal = '';

  getUrlImg(strImg: string){
    const images = require.context('@/assets/icons/', false, /\.svg$/)
    return images('./' + strImg + ".svg");
  }

  getUrlImg2(strImg: string){
    const images = require.context('@/assets/svg/', false, /\.svg$/)
    return images('./' + strImg + ".svg");
  }
}
  